<template>
    <div :id="blok._uid"
        class="w-full space-y-2xl">
        <div :id="elementId"></div>
    </div>
</template>

<script setup lang="ts">
import type { BlokComponent } from '~~/types';

const properties = defineProps<{
    blok: Extract<BlokComponent, { component: 'sb-flockler' }>
}>();

const elementId = computed(() => properties.blok.flockler_id);
useHead({
    link: [
        {
            href: 'https://plugins.flockler.com',
            rel: 'preconnect',
        },
        {
            href: 'https://plugins.flockler.com',
            rel: 'dns-preload',
        },
        {
            // eslint-disable-next-line max-len
            href: 'https://plugins.flockler.com/embed/16f7faa9c87082779605b98a3f2fcfd9/17b62b52bb5009394e1f4bc7f4bb5c20',
            rel: 'preload',
            as: 'script',
        },
    ],
    script: [
        {
            src: 'https://plugins.flockler.com/embed/16f7faa9c87082779605b98a3f2fcfd9/17b62b52bb5009394e1f4bc7f4bb5c20',
            tagPosition: 'bodyClose',
        },
    ],
});
</script>
